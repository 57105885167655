import { infoVergeLogo } from "../shared/Images.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function Header() {
  return (
    <div className={`container-fluid mt-5 p-3 footer`}>
      <div className="row p-3">
        <div className="col-12 col-md-4 d-flex flex-column">
          <img
            src={infoVergeLogo}
            className="p-0"
            alt="info-verge-logo"
            style={{ width: "125px" }}
          />
          <p className="text-white small">
            Copyright © ArkNote. 2021 All Rights Reserved.
          </p>
        </div>
        <div className="col-12 col-md-4 d-flex flex-column align-items-center">
          <div className="py-1 small">
            <div className="footer-header">Email</div>
            <p className="py-2 text-white">info@arknote.co.za</p>
            <div className="footer-header">Telephone</div>
            <p className="py-2 text-white">+27 12 771 2142</p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex flex-column align-items-center">
          <div className="py-1">
            <div className="footer-header small">Social Media</div>

            <div className="py-3 d-flex flex-row ">
              <div className="insta text-white p-2 pl-0">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
              <div className="twitter text-white p-2">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
              <div className="fb text-white p-2 pr-0">
                <FontAwesomeIcon icon={faFacebook} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
