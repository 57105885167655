function About() {
  return (
    <div className="container-fluid">
      <div className="row banner-home"></div>
      <div className="row">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">About</h1>
              <div className="row">
                <div className="col-12 col-md-10">
                  <p className="py-2">
                    ArkNote is a schools integrated services platform, that aims
                    to assist schools to become digitally transformed. Through
                    its services, users are able to interact with one another in
                    a secure manner an on a platform that is trustworthy.
                  </p>
                  <p className="py-2">
                    More importantly ArkNote aspire to empower schools to do
                    their work simpler and efficiently.
                  </p>
                  <p className="py-2">
                    ArkNote further creates a rich connection between schools,
                    parents and relevant Government departments, for the benefit
                    of the child.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
