import { logo } from "../shared/Images.js";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  let section = "";

  switch (location.pathname) {
    case "/about":
      section = "about";
      break;
    case "/services":
      section = "services";
      break;
    case "/contact":
      section = "contact";
      break;
    default:
      section = "";
      break;
  }

  return (
    <div className={`container-fluid ${section}`}>
      <div className="row header d-flex align-items-center">
        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
          <a className="py-2 d-flex" href="/" aria-label="ArkNote Logo">
            <img
              src={logo}
              alt="arknote-logo"
              className="site-logo"
              style={{ width: "160px" }}
            />
          </a>
        </div>
        <div className="col-12 col-md-4 main-menu d-flex flex-wrap align-items-center justify-content-start nav">
          <Link
            className={`pr-4 ${location.pathname === "/" ? "active" : ""}`}
            to="/"
          >
            Home
          </Link>
          <Link
            className={`pr-4 ${location.pathname === "/about" ? "active" : ""}`}
            to="/about"
          >
            About
          </Link>
          <Link
            className={`pr-4 ${
              location.pathname === "/services" ? "active" : ""
            }`}
            to="/services"
          >
            Services
          </Link>
          <Link
            className={`pr-4 ${
              location.pathname === "/contact" ? "active" : ""
            }`}
            to="/contact"
          >
            Contact Us
          </Link>
        </div>

        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
          <a className="btn btn-outline btn-request py-2 d-flex" href="/demo">
            Request A Demo
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
