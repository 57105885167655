import React, { useState } from "react";
import { SendFormData } from "../shared/Server-fuctions.js";

function Contact() {
  const [form, setState] = useState({
    name: "",
    email: "",
    telephone: "",
    comments: "",
  });

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.name !== "" && form.email !== "" && form.telephone !== "") {
      SendFormData(form, "../backend/contact-us.php");
    } else {
      alert("Please fill in all fields marked with *");
    }
  };

  return (
    <div className="container-fluid">
      <div className="banner row banner-contact"></div>
      <div className="row">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">Contact Us</h1>
              <div className="row">
                <div className="col-12 col-md-6">
                  Please use the contact us form, to capture relevant
                  information and <strong>“Submit”</strong>.
                </div>
                <div className="col-12 col-md-6">
                  <form onSubmit={handleSubmit} method="POST">
                    <div className="form-group">
                      <label
                        htmlFor="input-1st-name"
                        className="font-weight-bold"
                      >
                        Name<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-1st-name"
                        name="name"
                        aria-describedby="firstName"
                        placeholder="Name"
                        value={form.name}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-email" className="font-weight-bold">
                        Email Address<sup>*</sup>
                      </label>
                      <input
                        type="email"
                        className="form-control col-sm-8"
                        id="input-email"
                        name="email"
                        aria-describedby="emailAddress"
                        placeholder="Email address"
                        value={form.email}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-tel" className="font-weight-bold">
                        Telephone Number<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-tel"
                        name="telephone"
                        aria-describedby="telephone"
                        placeholder="Enter Telephone"
                        value={form.telephone}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="input-comments"
                        className="font-weight-bold"
                      >
                        Your message
                      </label>
                      <textarea
                        className="form-control col-sm-8"
                        id="input-comments"
                        name="comments"
                        rows="3"
                        value={form.comments}
                        onChange={updateField}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="btn px-5 bg-green text-white"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
