import axios from "axios";

export const SendFormData = (form, url) => {
  axios({
    method: "POST",
    url: url,
    data: form,
  }).then((response) => {
    if (response.data.status === "success") {
      alert("Message Sent.");
    } else if (response.data.status === "fail") {
      alert("Message failed to send.");
    }
  });
};
