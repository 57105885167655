import srcLogo from "../assets/logo.png";
import srcTutor from "../assets/logo.png";
import srcInfoVergeLogo from "../assets/info-verge.png";
import srcVideoPrev from "../assets/services_watch_arknote@2x.png";

import srcFeature1 from "../assets/feature-1.png";
import srcFeature2 from "../assets/feature-2.png";
import srcFeature3 from "../assets/feature-3.png";
import srcDesktopFeature from "../assets/desktop-feature.png";
import srcDemoImg from "../assets/demo-img.png";

import srcWelcomeArknote from "../assets/welcome_aknote@2x.png";
import srcGovArknote from "../assets/gov_arknote@2x.png";
import srcParentArknote from "../assets/parents_arknote@2x.png";
import srcRequestArknote from "../assets/request_arknote@2x.png";
import srcSchoolArknote from "../assets/schools_arknote@2x.png";

export const logo = srcLogo;
export const infoVergeLogo = srcInfoVergeLogo;

export const imgs = {
  tutor: srcTutor,
  videoPrev: srcVideoPrev,
  feature1: srcFeature1,
  feature2: srcFeature2,
  feature3: srcFeature3,
  desktopFeature: srcDesktopFeature,
  demoImg: srcDemoImg,

  welcomeArknote: srcWelcomeArknote,
  govArknote: srcGovArknote,
  parentArknote: srcParentArknote,
  requestArknote: srcRequestArknote,
  schoolArknote: srcSchoolArknote,
};
