import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { imgs } from "../shared/Images.js";
import Modal from "../components/Modal.js";

function Home() {
  const [playVideo, setState] = useState(false);
  const openModal = () => {
    setState(true);
  };

  const closeModal = () => {
    setState(false);
  };

  return (
    <div className="container-fluid">
      <div className="banner row banner-home">
        <div className="col-12">
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className="play-btn" onClick={openModal}>
              <FontAwesomeIcon icon={faPlayCircle} />
            </div>
            <div className="banner-title">
              Schools Integrated Services Platform
            </div>

            <div className="py-3">
              <button onClick={openModal} className="btn btn-danger">
                Watch video
              </button>
            </div>
            {playVideo && (
              <Modal
                url="../ArkNote General Promo PC.mp4"
                closeModal={closeModal}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">Welcome</h1>
              <div className="row">
                <div className="col-12 col-md-7">
                  <p className="py-2">
                    ArkNote provides schools the opportunity to become digitally
                    transformed. We walk the journey with you to bridge the
                    digital divide.
                  </p>
                </div>
                <div className="col-12 col-md-5 d-flex justify-content-center">
                  <img
                    alt="arknote"
                    src={imgs.welcomeArknote}
                    className="circular-img"
                  />
                </div>
                <div className="col-12">
                  <div className="py-3">
                    <a href="#" className="btn btn-normal">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-green">
        <div className="container">
          <div className="row d-flex py-5">
            <div className="col-12 mb-3">
              <h1 className="h1 main-header">Who is it for?</h1>
            </div>
            <div className="col-12 col-md-4 ">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  alt="schools"
                  src={imgs.schoolArknote}
                  className="circular-img border-white p-0"
                />
                <h5 className="h5 font-weight-bold py-3 text-white">Schools</h5>
              </div>
            </div>
            <div className="col-12 col-md-4 justify-content-center ">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  alt="parents &amp; gurdians"
                  src={imgs.parentArknote}
                  className="circular-img border-white p-0"
                />
                <h5 className="h5 font-weight-bold py-3 text-white">
                  Parents &amp; Guardians
                </h5>
              </div>
            </div>
            <div className="col-12 col-md-4 justify-content-center ">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  alt="goverment officials"
                  src={imgs.govArknote}
                  className="circular-img border-white p-0"
                />
                <h5 className="h5 font-weight-bold py-3 text-white">
                  Government Officials
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">Request a demo</h1>
              <div className="row">
                <div className="col-12 col-md-5 d-flex justify-content-center">
                  <img
                    alt=""
                    src={imgs.requestArknote}
                    className="circular-img p-0"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <p className="py-2">
                    To request for a technically lead Demo of some of the
                    service offerings of ArkNote, please complete the “Request a
                    Demo” form as instructed. A dedicated personnel will be in
                    contact with you soon.
                  </p>
                  <div className="py-3">
                    <a href="#" className="btn btn-normal">
                      Request Now
                    </a>
                  </div>
                </div>

                <div className="col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
