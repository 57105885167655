import React, { useState } from "react";
import { imgs } from "../shared/Images.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/Modal.js";

function Services() {
  const [playVideo, setState] = useState(false);
  const openModal = () => {
    setState(true);
  };

  const closeModal = () => {
    setState(false);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">How it works</h1>
              <div className="row">
                <div className="col-12 col-md-6">
                  <p className="py-2">
                    ArkNote aims to provide a fulfilled schooling environment
                    through technology. The following are some of the services
                    or products of ArkNote:
                  </p>
                  <ul className="py-2">
                    <li>School Communicator</li>
                    <li>School Readiness Assessment Instrument</li>
                    <li>Schools Covid-19 Screening and Reporting</li>
                  </ul>
                  <p className="py-2">
                    As part of the need to enable a digitally strong schooling
                    environment, ArkNote is currently building other school
                    focused solutions that will add more value to the schools.
                    Contact us for more information or custom requirements.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img
                    alt="video-preview"
                    src={imgs.videoPrev}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <button onClick={openModal} className="btn mr-3 btn-danger">
                <FontAwesomeIcon icon={faPlayCircle} />
                &nbsp;Watch video
              </button>
              <a href="#" className="btn btn-outline btn-request">
                <FontAwesomeIcon icon={faShareAlt} />
                &nbsp;Share
              </a>
            </div>

            {playVideo && (
              <Modal
                url="../ArkNote School Readiness-Assess.mp4"
                closeModal={closeModal}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row bg-grey">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">Mobile App Features</h1>
              <div className="row">
                <div className="col-12 col-md-4">
                  <img
                    alt="app-feature-1"
                    src={imgs.feature1}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <img
                    alt="app-feature-2"
                    src={imgs.feature2}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <img
                    alt="app-feature-3"
                    src={imgs.feature3}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 pt-4">
              <h1 className="h1 main-header">Desktop Features</h1>
              <div className="row">
                <div className="col-12 d-flex flex-column align-items-center">
                  <img
                    alt="app-feature-1"
                    src={imgs.desktopFeature}
                    style={{ width: "100%" }}
                  />
                  <a href="#" className="py-3">
                    Authentication to Register or Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
