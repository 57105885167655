function Modal(props) {
  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-close px-2 font-weight-bold"
            onClick={props.closeModal}
          >
            &times;
          </button>
        </div>
        <iframe
          title="video-previewer"
          width="100%"
          height="480"
          src={props.url}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Modal;
