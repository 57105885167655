import React, { useState } from "react";
import { imgs } from "../shared/Images.js";
import { SendFormData } from "../shared/Server-fuctions.js";

function Demo() {
  const [form, setState] = useState({
    name: "",
    surname: "",
    company: "",
    email: "",
    telephone: "",
    comments: "",
    jobtitle: "",
  });

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      form.name !== "" &&
      form.surname !== "" &&
      form.telephone !== "" &&
      form.company !== "" &&
      form.email !== "" &&
      form.jobtitle !== ""
    ) {
      SendFormData(form, "../backend/demo.php");
    } else {
      alert("Please fill in all fields marked with *");
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <h1 className="h1 main-header">Request a demo</h1>
              <div className="row">
                <div className="col-12 col-md-6">
                  <form onSubmit={handleSubmit} method="POST">
                    <div className="form-group">
                      <label
                        htmlFor="input-1st-name"
                        className="font-weight-bold"
                      >
                        First Name<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-1st-name"
                        name="name"
                        aria-describedby="firstName"
                        placeholder="First Name"
                        value={form.name}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="input-surname"
                        className="font-weight-bold"
                      >
                        Surname<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-surname"
                        name="surname"
                        aria-describedby="surname"
                        placeholder="Surname"
                        value={form.surname}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="input-company"
                        className="font-weight-bold"
                      >
                        Company<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-company"
                        name="company"
                        aria-describedby="company"
                        placeholder="Company"
                        value={form.company}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-email" className="font-weight-bold">
                        Email Address<sup>*</sup>
                      </label>
                      <input
                        type="email"
                        className="form-control col-sm-8"
                        id="input-email"
                        name="email"
                        aria-describedby="emailAddress"
                        placeholder="Email address"
                        value={form.email}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="input-jobtitle"
                        className="font-weight-bold"
                      >
                        Job Title<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-jobtitle"
                        name="jobtitle"
                        aria-describedby="jobTitle"
                        placeholder="Job Title"
                        value={form.jobtitle}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="input-tel" className="font-weight-bold">
                        Telephone Number<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control col-sm-8"
                        id="input-tel"
                        name="telephone"
                        aria-describedby="telephone"
                        placeholder="Enter Telephone"
                        value={form.telephone}
                        onChange={updateField}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="input-comments"
                        className="font-weight-bold"
                      >
                        Comments
                      </label>
                      <textarea
                        className="form-control col-sm-8"
                        id="input-comments"
                        name="comments"
                        rows="3"
                        value={form.comments}
                        onChange={updateField}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="btn px-5 bg-green text-white"
                    >
                      Submit
                    </button>
                  </form>
                </div>

                <div className="col-12 col-md-6">
                  <img
                    alt="app-feature-1"
                    src={imgs.demoImg}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demo;
